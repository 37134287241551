/**@jsx jsx */
import { jsx } from 'theme-ui';

const FullPageModal: React.FC<{ open: boolean }> = ({
  open = false,
  children,
}) => {
  return (
    <div
      className={open ? `open` : ''}
      sx={{
        height: '100vh',
        width: '100%',
        backgroundColor: 'background',
        zIndex: 600,
        position: 'fixed',
        top: 0,
        left: 0,
        transition: 'all 0.1s ease-in-out',
        transformOrigin: 'bottom',
        transform: 'translate3d(0, 100%, 0)',
        opacity: 0,
        '&.open': {
          opacity: 1,
          transform: 'translate3d(0, 0%, 0)',
        },
      }}
      children={children}
    />
  );
};

export default FullPageModal;
