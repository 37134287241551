/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import { Input, InputError, InputWrap, Label, ShowPassword } from './styled';

interface InputFieldProps {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  required?: boolean;
  children?: React.ReactNode;
  'auto-complete'?: string;
  min?: string;
  max?: string;
  pattern?: string;
  hasError?: boolean;
}

export const InputField: React.FC<InputFieldProps> = ({
  hasError,
  children,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [focused, setFocused] = useState(false);

  return (
    <InputWrap>
      <Input
        {...field}
        {...props}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <Label
        hasValue={!!field.value || focused}
        hasError={hasError || (meta.touched && !!meta.error)}
        htmlFor={props.name}
        focused={focused}
      >
        {props.label}
      </Label>

      <InputError message={meta.touched && meta.error ? meta.error : null} />
      {children}
      {props.type === 'date' && (
        <time sx={{ fontSize: [3] }} dateTime={`${field.value}`} />
      )}
    </InputWrap>
  );
};

export const PasswordField: React.FC<
  InputFieldProps & { showPassword?: boolean }
> = ({ showPassword = true, ...props }) => {
  const [shown, setShown] = useState(false);

  return (
    <InputField
      auto-complete='current-password'
      {...props}
      type={shown ? 'text' : 'password'}
    >
      {showPassword && <ShowPassword showPassword={setShown} shown={shown} />}
    </InputField>
  );
};

export const DateField: React.FC<InputFieldProps> = ({
  hasError,
  children,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleChange = (value: Date | Date[]) => {
    setValue(value);
    setCalendarOpen(false);
  };

  const ref = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        setCalendarOpen(false);
      }
    };

    if (calendarOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [calendarOpen, ref]);

  return (
    <InputWrap>
      <Input
        {...field}
        {...props}
        value={field.value && format(field.value, 'MMMM d, yyyy')}
        onClick={() => setCalendarOpen(true)}
        onFocus={() => setCalendarOpen(true)}
      />
      <Label
        hasValue={!!field.value}
        hasError={hasError || (meta.touched && !!meta.error)}
        htmlFor={props.name}
      >
        {props.label}
      </Label>

      {calendarOpen && (
        <Box
          ref={ref}
          sx={{
            position: 'absolute',
            top: -100,
            left: 0,
            background: 'white',
            zIndex: 999,
          }}
        >
          <Calendar
            defaultView='month'
            minDate={new Date()}
            value={field.value || new Date()}
            onChange={handleChange}
          />
        </Box>
      )}

      <InputError message={meta.touched && meta.error ? meta.error : null} />
      {children}
      {props.type === 'date' && (
        <time sx={{ fontSize: [3] }} dateTime={`${field.value}`} />
      )}
    </InputWrap>
  );
};
