import React, { Dispatch, SetStateAction } from 'react';
import { Flex } from 'theme-ui';
import { LayoutType } from './EventsLayoutType';
import { IconButt } from '../buttons';
import { BsFillGrid3X2GapFill } from 'react-icons/bs';

const ListIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0H24V24H0V0Z'
      stroke='black'
      strokeOpacity='0.01'
      strokeWidth='0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 18H21V12H4V18ZM4 5V11H21V5H4Z'
      fill='currentColor'
    />
  </svg>
);

const LayoutSwitch: React.FC<{
  current: LayoutType;
  changeLayout: Dispatch<SetStateAction<LayoutType>>;
}> = ({ current, changeLayout }) => {
  return (
    <Flex>
      <IconButt
        onClick={() => changeLayout('grid')}
        active={current === 'grid'}
      >
        <BsFillGrid3X2GapFill size={24} />
      </IconButt>
      <IconButt
        onClick={() => changeLayout('list')}
        active={current === 'list'}
      >
        <ListIcon />
      </IconButt>
    </Flex>
  );
};

export default LayoutSwitch;
