import React from 'react';
import { Box } from 'theme-ui';

const PageLayout: React.FC<{ bg?: string }> = ({ children }) => (
  <Box
    sx={{
      width: '100%',
      minHeight: '100vh',
      backgroundColor: 'background',
    }}
  >
    {children}
  </Box>
);

export default PageLayout;
