import React from 'react';
import TextLink from './TextLink';

export const SignInLink = () => {
  return (
    <TextLink variant='links.signInLink' to='/signin'>
      Already have an account? <strong>SIGN IN</strong>
    </TextLink>
  );
};

export const SignUpLink = () => {
  return (
    <TextLink variant='links.signInLink' to='/signup'>
      Don’t have account? <strong>SIGN UP</strong>
    </TextLink>
  );
};
