import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Text } from 'theme-ui';
import { GoBackIcon } from '../icons';
import * as routes from '../../routes/const';

const GoBackLink = () => {
  return (
    <Link to={routes.DASHBOARD}>
      <Box
        sx={{
          display: ['none', null, null, 'flex'],
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            transform: 'translate3d(0px, 0, 0)',
            transition: 'transform 0.2s ease-in-out',
          },
          '&:hover': {
            '& svg': {
              transform: 'translate3d(-8px, 0, 0)',
            },
          },
        }}
      >
        <GoBackIcon />
        <Text>Back to events</Text>
      </Box>
    </Link>
  );
};

export default GoBackLink;
