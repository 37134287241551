import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export const CreateEventFormContext = createContext<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}>({ open: false, setOpen: () => {} });

export const CreateEventFormContextProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    open
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'scroll');
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <CreateEventFormContext.Provider
      value={{
        open,
        setOpen,
      }}
    >
      {children}
    </CreateEventFormContext.Provider>
  );
};

export const useCreateEventFormContext = () => {
  return useContext(CreateEventFormContext);
};
