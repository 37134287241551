import React from 'react';
import { Card, Box, Text, Heading, Flex } from 'theme-ui';
import { BsPersonFill } from 'react-icons/bs';
import EventButton from './EventCardButton';
import EventDate from './EventDate';
import { Link } from 'react-router-dom';
import { EventInState } from '../../types';

export interface EventCardProps {
  event: EventInState;
  joined?: boolean;
  isOwner?: boolean;
}

export const EventGridCard: React.FC<EventCardProps> = ({
  event,
  joined = false,
  isOwner = false,
}) => {
  const {
    id,
    title,
    description,
    attendees,
    startsAt,
    capacity,
    owner,
  } = event;

  return (
    <Card as='article' variant='event'>
      <EventDate date={startsAt} />

      <Link to={`/events/${id}`}>
        <Box sx={{ mb: [6], mt: [5] }}>
          <Heading variant='cardHeading'>{title}</Heading>
          <Text as='p' variant='eventAuthor'>
            {owner.firstName + ' ' + owner.lastName}
          </Text>
        </Box>
      </Link>

      <Text
        as='p'
        variant='cardDescription'
        sx={{
          maxWidth: [250],
          maxHeight: 90,
          marginBottom: [4],
          overflow: 'hidden',
          whiteSpace: 'normal',
          textOverflow: 'ellipsis',
        }}
      >
        {description}
      </Text>

      <Flex
        sx={{
          flex: '1 0 auto',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Flex sx={{ alignItems: 'center', color: 'textDarkGrey' }}>
          <BsPersonFill style={{ width: 22, height: 22 }} />
          <Text
            sx={{
              marginLeft: [2],
              lineHeight: '14px',
              fontSize: [1],
              color: 'textDarkGrey',
            }}
            as='p'
          >
            {attendees.length} of {capacity}
          </Text>
        </Flex>

        <EventButton
          startsAt={startsAt}
          joined={joined}
          isOwner={isOwner}
          id={event.id}
        />
      </Flex>
    </Card>
  );
};

export const EventListCard: React.FC<EventCardProps> = ({
  event,
  isOwner = false,
  joined = false,
}) => {
  const {
    id,
    title,
    description,
    attendees,
    startsAt,
    capacity,
    owner,
  } = event;
  return (
    <Card as='article' variant='eventList'>
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          gridArea: 'title',
        }}
      >
        <Link to={`/events/${id}`}>
          <Heading variant='eventListCardHeading'>{title}</Heading>
        </Link>
      </Box>

      <Box
        sx={{
          gridArea: 'desc',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          alignSelf: ['start', null, null, 'center'],
        }}
      >
        <Text as='p' variant='cardDescription'>
          {description}
        </Text>
      </Box>

      <Box sx={{ gridArea: 'owner', display: ['none', null, null, 'block'] }}>
        <Text variant='eventAuthor'>
          {owner.firstName + ' ' + owner.lastName}
        </Text>
      </Box>

      <Box sx={{ gridArea: 'date' }}>
        <EventDate date={startsAt} />
      </Box>

      <Box sx={{ gridArea: 'capac' }}>
        <Text variant='cardDescription'>
          {attendees.length} of {capacity}
        </Text>
      </Box>

      <Box sx={{ justifySelf: 'end', alignSelf: 'center', gridArea: 'button' }}>
        <EventButton
          startsAt={startsAt}
          joined={joined}
          isOwner={isOwner}
          id={event.id}
        />
      </Box>
    </Card>
  );
};
