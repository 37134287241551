import React from 'react';
import {
  useAuthenticated,
  useAuthLoading,
} from '../../store/models/auth/selectors';
import { useLocation, Redirect, Route, RouteProps } from 'react-router-dom';
import FullPageLoad from '../../components/LoadingFullPage';

const PrivateRoute = (props: RouteProps) => {
  const authenticated = useAuthenticated();
  const loading = useAuthLoading();
  const location = useLocation();

  if (loading) {
    return <FullPageLoad />;
  }

  if (authenticated) {
    return <Route {...props} />;
  }

  return <Redirect to={{ pathname: '/signin', state: { from: location } }} />;
};

export default PrivateRoute;
