import React, { ReactNode } from 'react';
import { Box } from 'theme-ui';
import { DateField, InputField } from '../../components/Inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { CreateEventSchema } from '../../schemas';
import { CreateEventPayload } from '../../types';

const initialDefault = {
  title: '',
  description: '',
  // setting date on the calendar in the DateField
  //@ts-ignore
  date: new Date(),
  time: '',
  capacity: 1,
};

const EventForm: React.FC<{
  initialValues?: CreateEventPayload;
  onSubmit: (
    values: CreateEventPayload,
    actions: FormikHelpers<CreateEventPayload>
  ) => void;
  id?: string;
  children?: ReactNode | ReactNode[];
}> = ({ initialValues = initialDefault, onSubmit, id, children }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateEventSchema}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<CreateEventPayload>) => (
        <Form id={id}>
          <Box my={[7]}>
            <InputField name='title' type='text' label='Title' />
            <InputField name='description' type='text' label='Description' />
            <DateField name='date' type='text' label='Date' />
            <InputField
              name='time'
              type='time'
              label='Time'
              pattern='[0-9]{2}:[0-9]{2}'
            />
            <InputField name='capacity' type='number' label='Capacity' />
          </Box>
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default EventForm;
