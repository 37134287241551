import { useSelector } from 'react-redux';
import { RootState } from '../..';

export const useAuthenticated = () =>
  useSelector((state: RootState) => state.auth.user !== null);

export const useUser = () => useSelector((state: RootState) => state.auth.user);

export const useAuthLoading = () =>
  useSelector((state: RootState) => state.auth.loading);

export const useSubmitError = () =>
  useSelector((state: RootState) => state.auth.error);
