import React from 'react';
import { Box, Flex } from 'theme-ui';
import Cocky from '../Quote';

const QuoteLayout: React.FC = ({ children }) => {
  return (
    <Flex sx={{ width: '100%', height: '100vh', backgroundColor: '#ffffff' }}>
      <Cocky />
      <Box sx={{ flex: '3 0 auto' }}>{children}</Box>
    </Flex>
  );
};

export default QuoteLayout;
