import React from 'react';
import { Box } from 'theme-ui';
import { TrooperIcon } from '../icons';
import Centered from './Centered';
import Container from './Container';
import QuoteLayout from './QuoteLayout';

const TrooperLayout: React.FC = ({ children }) => {
  return (
    <QuoteLayout>
      <Box
        sx={{
          color: 'background',
          top: ['25%'],
          left: ['-20%', null, '22%'],
          position: 'fixed',
        }}
      >
        <TrooperIcon />
      </Box>
      <Container>
        <Centered>
          <Box sx={{ maxWidth: 530, position: 'relative', zIndex: 200 }}>
            {children}
          </Box>
        </Centered>
      </Container>
    </QuoteLayout>
  );
};

export default TrooperLayout;
