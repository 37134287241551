import { isFuture, isPast } from 'date-fns';
import { EventsFilter } from '../types/Event';

const dateFilter = (startsAt: string, filter: EventsFilter) => {
  const parsed = new Date(startsAt);
  switch (filter) {
    case 'all':
      return true;
    case 'past':
      return isPast(parsed);
    case 'future':
      return isFuture(parsed);
    default:
      return true;
  }
};

export const composeDate = (date: Date, time: string): Date => {
  const [hours, minutes] = time.split(':');
  const formated = new Date(date);
  formated.setHours(Number(hours), Number(minutes));
  return formated;
};

/**
 * Formats date for format usbale in the components.
 *
 * @param date
 */
export const formatDate = (dateString: string) => {
  const converted = new Date(dateString);
  const date = converted.toLocaleString('en', {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });
  const [hours, minutes, rest] = converted.toLocaleTimeString('en').split(':');
  const ampm = rest.split(' ')[1];
  return `${date} - ${hours}:${minutes} ${ampm.toUpperCase()}`;
};

/**
 * Parses date form input
 * @param date
 */
const parseInputDate = (date: string, time: string) => {
  return new Date();
};

export { dateFilter, parseInputDate };
