import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from 'theme-ui';
import { Button } from '../../components/buttons';
import { InputField, PasswordField } from '../../components/Inputs';
import { SignUpLink } from '../../components/links';
import { SignUpSchema } from '../../schemas';
import { useAuthLoading } from '../../store/models/auth/selectors';
import { SignUpPayload } from '../../types';
import { InputsWrapper } from '../SignIn/form';

const SignUpForm = ({ submitError }: { submitError: string | null }) => {
  const dispatch = useDispatch();

  const submitLoading = useAuthLoading();

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      }}
      validationSchema={SignUpSchema}
      onSubmit={(values, actions) => {
        const { confirmPassword, ...rest } = values;
        dispatch.auth.signUp(rest);
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting }: FormikProps<SignUpPayload>) => (
        <Form>
          <InputsWrapper>
            <InputField
              hasError={!!submitError}
              name='firstName'
              type='text'
              label='First Name'
            />
            <InputField
              hasError={!!submitError}
              name='lastName'
              type='text'
              label='Last Name'
            />
            <InputField
              hasError={!!submitError}
              name='email'
              type='email'
              label='Email'
            />
            <PasswordField
              hasError={!!submitError}
              type='password'
              name='password'
              label='Password'
              showPassword={true}
            />
            <PasswordField
              hasError={!!submitError}
              type='password'
              name='confirmPassword'
              label='Repeat password'
              showPassword={false}
            />
          </InputsWrapper>

          <Box sx={{ display: ['block', null, 'none'], mb: [7] }}>
            <SignUpLink />
          </Box>

          <Button
            variants={['primary', 'big']}
            disabled={isSubmitting || submitLoading}
            type='submit'
          >
            {isSubmitting ? '...' : 'Submit'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
