/** @jsx jsx */
import { BlockquoteHTMLAttributes } from 'react';
import { jsx } from 'theme-ui';

export const Blockquote: React.FC<BlockquoteHTMLAttributes<HTMLElement>> = (
  props
) => (
  <blockquote
    sx={{
      '& p': {
        '&:before': {
          content: `'\u201C'`,
        },
        '&:after': {
          content: `'\u201D'`,
        },
      },
    }}
    {...props}
  />
);
