/**@jsx jsx */
import { jsx } from 'theme-ui';
import { FC } from 'react';

const Badge: FC<{ filled?: boolean }> = ({ children, filled = true }) => (
  <div
    children={children}
    sx={{
      px: [4],
      color: 'textDarkGrey',
      bg: filled ? 'lightGrey' : 'white',
      borderRadius: '100px',
      border: '2px solid',
      borderColor: 'lightGrey',
      lineHeight: '31px',
      height: '32px',
      fontSize: '13px',
    }}
  />
);

export default Badge;
