import React, { forwardRef } from 'react';
import {
  Button as TUIButt,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Text,
  useThemeUI,
} from 'theme-ui';
import { mapThemeUiVariants } from '../../utils/takeThemeVariants';
import { CloseIcon } from '../icons';

export const IconButt = forwardRef<
  HTMLButtonElement,
  IconButtonProps & { active?: boolean }
>(({ active = false, ...props }, ref) => (
  <IconButton
    ref={ref}
    sx={{
      p: 0,
      width: 'fit-content',
      height: '100%',
      color: active ? 'text' : 'lightGrey',
      cursor: 'pointer',
      '&:focus': { outline: 'none' },
      display: 'flex',
      justifyContent: 'center',
    }}
    {...props}
  />
));

export const TextButt = forwardRef<
  HTMLButtonElement,
  ButtonProps & { active?: boolean }
>(({ active = false, sx, ...props }, ref) => (
  <Button
    ref={ref}
    {...props}
    sx={{
      cursor: 'pointer',
      backgroundColor: 'transparent',
      textTransform: 'uppercase',
      p: [0],
      // fontWeight: 500,
      fontSize: [1],
      alignItems: 'center',
      color: 'textDarkGrey',
      '&:hover': {
        color: 'text',
      },
      ...sx,
    }}
  />
));

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & { variants?: string[]; variant?: string }
>(({ variants = [], variant, ...props }, ref) => {
  const { theme } = useThemeUI();

  return (
    <TUIButt
      ref={ref}
      variant={variant}
      sx={{
        cursor: 'pointer',
        letterSpacing: '1px',
        fontFamily: 'body',
        lineHeight: 'button',
        borderRadius: 'button',
        fontWeight: 'semiBold',
        textTransform: 'uppercase',
        fontSize: [2],
        '&: outlined': {
          outline: 'text',
        },
        ...mapThemeUiVariants({
          variants,
          key: 'buttons',
          theme,
        }),
      }}
      {...props}
    />
  );
});

export const CloseButt: React.FC<IconButtonProps> = ({ onClick }) => (
  <IconButt
    // @ts-ignore
    onClick={onClick}
  >
    <CloseIcon />
    <Text
      sx={{ lineHeight: '40px', fontSize: [2], color: 'text', marginLeft: [2] }}
    >
      Close
    </Text>
  </IconButt>
);
