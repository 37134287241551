/**@jsx jsx */
import { jsx } from 'theme-ui';
import { LayoutType } from './EventsLayoutType';

const EventsGrid: React.FC<{
  layout?: LayoutType;
}> = ({ layout = 'grid', children }) => (
  <ul
    sx={{
      listStyle: 'none',
      display: 'grid',
      width: '100%',
      marginBottom: [12],
      gap: 16,
      gridTemplateColumns:
        layout === 'grid' ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr',
    }}
  >
    {children}
  </ul>
);

export default EventsGrid;
