/**@jsx jsx */
import { Box, Flex, jsx } from 'theme-ui';
import { FC, ImgHTMLAttributes } from 'react';

export const getInitials = (firstName: string, lastName: string) =>
  `${firstName[0]}${lastName[0]}`;

const Initials: FC = ({ children }) => (
  <Box
    sx={{
      color: 'textMediumGrey',
      top: 0,
      left: 0,
    }}
  >
    {children}
  </Box>
);

const Avatar: FC<ImgHTMLAttributes<
  HTMLImageElement & { width?: number; height?: number }
>> = ({ width = 40, height = 40, alt }) => {
  return (
    <Flex
      sx={{
        width,
        height,
        borderRadius: '100%',
        position: 'relative',
        backgroundColor: 'lightGrey',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Initials>{alt}</Initials>
    </Flex>
  );
};

export default Avatar;
