import React from 'react';
import { Box, Flex, Heading, Text } from 'theme-ui';
import { User } from '../../types';
import Badge from '../Badge';
import Card from '../Card';

const AttendeesCard: React.FC<{ attendees: any[]; user: User }> = ({
  attendees,
  user,
}) => {
  return (
    <Box>
      <Card as='article' sx={{ minHeight: 300 }}>
        <Heading sx={{ mb: [3] }}>Attendees</Heading>
        <Flex
          sx={{
            flexWrap: 'wrap',
          }}
        >
          {!attendees.length && (
            <Text variant='cardDescription'>No people attending</Text>
          )}
          {attendees.map((att) => (
            <Box
              sx={{
                mr: [3],
                mb: [3],
              }}
            >
              {att.id === user?.id ? (
                <Badge filled={false}>You</Badge>
              ) : (
                <Badge>{`${att.firstName} ${att.lastName}`}</Badge>
              )}
            </Box>
          ))}
        </Flex>
      </Card>
    </Box>
  );
};

export default AttendeesCard;
