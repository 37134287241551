import React from 'react';
import { Box } from 'theme-ui';
import { EventCardProps, EventGridCard, EventListCard } from './EventCard';
import { LayoutType } from './EventsLayoutType';

const EventGridItem: React.FC<EventCardProps & { layout: LayoutType }> = ({
  layout,
  ...props
}) => (
  <Box as='li' sx={{ maxHeight: 300 }}>
    {layout === 'grid' ? (
      <EventGridCard {...props} />
    ) : (
      <EventListCard {...props} />
    )}
  </Box>
);

export default EventGridItem;
