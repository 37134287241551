import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Flex, Text } from 'theme-ui';
import Avatar, { getInitials } from '../Avatar';
import { TextButt } from '../buttons';
import Dropdown from '../Dropdown';
import TextLink from '../links/TextLink';
import * as routes from '../../routes/const';
const LogOutButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    dispatch.auth.signOut();
    history.replace(routes.DASHBOARD);
  };

  return (
    <TextButt sx={{ variant: 'text.textLink' }} onClick={handleClick}>
      Log Out
    </TextButt>
  );
};

const Account: React.FC<{
  firstName: string;
  lastName: string;
  uid: string;
}> = ({ firstName, lastName, uid }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        color: 'textDarkGrey',
        fontSize: 1,
        fontWeight: 500,
        fontFamily: 'body',
      }}
    >
      <Link to={`/profiles/${uid}`}>
        <Avatar alt={getInitials(firstName, lastName)} />
      </Link>
      <Text
        sx={{ ml: [2], display: ['none', null, 'block'] }}
      >{`${firstName} ${lastName}`}</Text>

      <Dropdown>
        <div>
          <TextLink variant='links.textLink' to='/profile'>
            Profile
          </TextLink>
        </div>
        <div>
          <LogOutButton />
        </div>
      </Dropdown>
    </Flex>
  );
};

export default Account;
