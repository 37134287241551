import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box } from 'theme-ui';
import { useUser } from '../../store/models/auth/selectors';
import { CloseButt } from '../buttons';
import { useCreateEventFormContext } from '../create-event/CreateEventFormContext';
import EventioLogo from '../icons/EventioLogo';
import { GoBackLink, SignInLink, SignUpLink } from '../links';
import Account from './Account';
import HeaderContainer from './HeaderContainer';
import * as routes from '../../routes/const';

const Header = () => {
  const user = useUser();
  const { pathname } = useLocation();

  const [logoColor, setLogoColor] = useState<string[]>(['text']);

  useEffect(() => {
    ['/signin', '/signup', '/not-found', '/error'].includes(pathname)
      ? setLogoColor(['text', 'text', 'text', 'white'])
      : setLogoColor(['text']);
  }, [pathname]);

  const { open: formOpen, setOpen } = useCreateEventFormContext();

  return (
    <HeaderContainer>
      <Box sx={{ position: 'relative', zIndex: 99999 }}>
        <Link to={routes.DASHBOARD}>
          <EventioLogo color={logoColor} />
        </Link>
      </Box>

      {pathname.includes('/events/') && !formOpen && <GoBackLink />}

      {formOpen ? (
        <CloseButt
          //@ts-ignore
          onClick={() => setOpen(false)}
        />
      ) : user === null ? (
        <Box sx={{ display: ['none', null, 'block'] }}>
          {pathname === routes.SIGN_IN ? <SignUpLink /> : <SignInLink />}
        </Box>
      ) : (
        <Account
          uid={user.id}
          firstName={user.firstName}
          lastName={user.lastName}
        />
      )}
    </HeaderContainer>
  );
};
export default Header;
