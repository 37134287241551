import { isPast } from 'date-fns';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Heading, Text } from 'theme-ui';
import { Button } from '../../components/buttons';
import { useCreateEventFormContext } from '../../components/create-event/CreateEventFormContext';
import EventForm from '../../components/forms/EventForm';
import { Centered, Container } from '../../components/layouts';
import {
  useEventsError,
  useEventsLoading,
} from '../../store/models/events/selectors';
import { CreateEventPayload } from '../../types';
import { composeDate } from '../../utils/dates';

const CreateEventCard = () => {
  const error = useEventsError();
  return (
    <Container>
      <Centered>
        <Card variant='form' sx={{ mt: [8, null, 0] }}>
          <div>
            <Heading>Create new event</Heading>
            {error ? (
              <Text variant='submitError'></Text>
            ) : (
              <Text variant='subheading'>Enter your details below.</Text>
            )}
          </div>
          <CreateEventForm />
        </Card>
      </Centered>
    </Container>
  );
};

const initialValues = {
  title: '',
  description: '',
  date: '',
  time: '',
  capacity: 1,
};

export const CreateEventForm = () => {
  const dispatch = useDispatch();
  const loading = useEventsLoading();
  const { setOpen } = useCreateEventFormContext();

  const onSubmit = (
    values: CreateEventPayload,
    actions: FormikHelpers<CreateEventPayload>
  ) => {
    const { date, time, ...rest } = values;
    // @ts-ignore
    const startsAt = composeDate(date, time);

    if (isPast(startsAt)) {
      actions.setErrors({
        time: 'This time is in the past, mate',
      });
      actions.setSubmitting(false);
      return;
    }

    dispatch.events.createEvent({
      ...rest,
      startsAt: startsAt,
    });
    actions.setSubmitting(false);
    if (!loading) {
      // @ts-ignore
      setOpen(false);
    }
  };

  return (
    <EventForm
      onSubmit={onSubmit}
      // @ts-ignore
      initialValues={initialValues}
    >
      <Button type='submit' variants={['green', 'big']}>
        Create new event
      </Button>
    </EventForm>
  );
};

export default CreateEventCard;
