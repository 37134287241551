import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../components/buttons';
import { InputField, PasswordField } from '../../components/Inputs';
import { SignUpLink } from '../../components/links';
import { SignInSchema } from '../../schemas';
import { SignInPayload } from '../../types';
import { Form, Formik, FormikProps } from 'formik';
import { useAuthLoading } from '../../store/models/auth/selectors';
import { Box } from 'theme-ui';

export const FormWrap: React.FC = ({ children }) => (
  <Box
    sx={{
      width: '100%',
      px: [5],
      maxWidth: [460],
      textAlign: ['center', null, 'left'],
    }}
    children={children}
  />
);

export const InputsWrapper: React.FC = ({ children }) => (
  <Box
    sx={{
      mt: [5, null, null, 7],
      mb: [5, null, 9],
    }}
    children={children}
  />
);

const SignInForm = ({ submitError }: { submitError: string | null }) => {
  const dispatch = useDispatch();

  const submitLoading = useAuthLoading();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={SignInSchema}
      onSubmit={(values, actions) => {
        dispatch.auth.signIn(values);

        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting }: FormikProps<SignInPayload>) => (
        <Form>
          <InputsWrapper>
            <InputField
              hasError={!!submitError}
              required={true}
              name='email'
              type='email'
              label='Email'
            />
            <PasswordField
              hasError={!!submitError}
              auto-complete='current-password'
              type='password'
              name='password'
              label='Password'
            />
          </InputsWrapper>

          <Box sx={{ display: ['block', null, 'none'], mb: [7] }}>
            <SignUpLink />
          </Box>

          <Button
            variants={['primary', 'big']}
            disabled={isSubmitting || submitLoading}
            type='submit'
          >
            {isSubmitting ? '...' : 'Submit'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
