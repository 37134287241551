import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Heading, Text } from 'theme-ui';
import { Button } from '../../components/buttons';
import { TrooperIcon } from '../../components/icons';
import { QuoteLayout, TrooperLayout } from '../../components/layouts';
import { Centered, Container } from '../../components/layouts';
import { DASHBOARD } from '../../routes/const';

const NotFound = () => {
  const history = useHistory();

  return (
    <TrooperLayout>
      <Heading>404 Error - page not found</Heading>
      <Text variant='subheading' sx={{ mb: [40] }}>
        Seems like Darth Vader just hits our website and drops it down. Please
        press the refresh button and everything should be fine again.{' '}
      </Text>
      <Button
        variants={['big', 'black']}
        onClick={() => history.replace(DASHBOARD)}
      >
        REFRESH
      </Button>
    </TrooperLayout>
  );
};

export default NotFound;
