import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import * as routes from './const';

import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import EventDetail from '../pages/EventDetail';
import NotFound from '../pages/NotFound';
import Profile from '../pages/Profile';
import ErrorPage from '../pages/Error';
import EventEdit from '../pages/EventEdit';

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.DASHBOARD} exact component={Dashboard} />
      <PrivateRoute path={routes.EVENT_DETAIL} exact component={EventDetail} />
      <PrivateRoute path={routes.EVENT_EDIT} exact component={EventEdit} />
      <PrivateRoute path={routes.PROFILE} exact component={Profile} />
      <Route path={routes.SIGN_IN} exact component={SignIn} />
      <Route path={routes.SIGN_UP} exact component={SignUp} />
      <Route path={routes.ERROR} exact component={ErrorPage} />
      <Route path={routes.NOT_FOUND} component={NotFound} />
      <Route render={() => <Redirect to={routes.NOT_FOUND} />} />
    </Switch>
  );
};

export default Routes;
