import React from 'react';
import { Heading, Text } from 'theme-ui';
import { Centered, QuoteLayout } from '../../components/layouts';
import RedirectAuthenticated from '../../components/RedirectAuthenticated';
import { useSubmitError } from '../../store/models/auth/selectors';
import SignInForm, { FormWrap } from './form';

const SignIn = () => {
  const submitError = useSubmitError();

  return (
    <QuoteLayout>
      <RedirectAuthenticated />
      <Centered>
        <FormWrap>
          <Heading as='h1'>Sign in to Eventio.</Heading>
          {submitError ? (
            <Text variant='submitError'>{submitError}</Text>
          ) : (
            <Heading as='h2' variant='subheading'>
              Enter your details below.
            </Heading>
          )}
          <SignInForm submitError={submitError} />
        </FormWrap>
      </Centered>
    </QuoteLayout>
  );
};

export default SignIn;
