import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CreateEventFormContextProvider } from './components/create-event/CreateEventFormContext';
import Header from './components/Header';
import Routes from './routes';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.auth.initAuth();
  }, []);

  return (
    <BrowserRouter>
      <CreateEventFormContextProvider>
        <Header />
        <Routes />
      </CreateEventFormContextProvider>
    </BrowserRouter>
  );
}

export default App;
