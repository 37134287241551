import React from 'react';
import { Flex, Grid } from 'theme-ui';

const EventDetailContent: React.FC = ({ children }) => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: ['1fr', null, null, '2fr 1fr'],
        gridTemplateRows: ['1fr 1fr', null, null, '1fr'],
        gap: [5],
      }}
    >
      {children}
    </Grid>
  );
};

export default EventDetailContent;
