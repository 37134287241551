/**@jsx jsx */
import { jsx, Flex, Grid } from 'theme-ui';

const HeaderContainer: React.FC = (props) => (
  <Flex
    sx={{
      position: 'fixed',
      zIndex: 800,
      justifyContent: 'space-between',
      alignItems: 'center',
      top: 4,
      width: '100%',
      height: [40],
      px: ['24px', null, '40px'],
    }}
    {...props}
  />
);

export default HeaderContainer;
