import Cookie from 'js-cookie';

export const getRefreshToken = () => {
  return Cookie.get('refresh_token');
};

export const removeRefreshToken = () => {
  Cookie.remove('refresh_token');
};

export const setRefreshToken = (token: string) => {
  Cookie.set('refresh_token', token, {
    sameSite: 'strict',
    secure: process.env.NODE_ENV === 'production',
    expires: 7,
  });
};

export const getAccessToken = () => {
  return Cookie.get('access_token');
};

export const removeAccessToken = () => {
  Cookie.remove('access_token');
};

export const setAccessToken = (token: string) => {
  Cookie.set('access_token', token, {
    sameSite: 'strict',
    secure: process.env.NODE_ENV === 'production',
    expires: 1 / 24,
  });
};
