import React from 'react';
import { Flex } from 'theme-ui';

const ControllsContainer: React.FC = ({ children }) => {
  return (
    <Flex
      sx={{
        width: '100%',
        zIndex: 300,
        position: 'sticky',
        pt: [11, null, 12],
        pb: [5, null, 7],
        top: [-7, null, -10],
        backgroundColor: 'background',
        boxShadow: `20px 0 0 #f2f2f2, -20px 0 0 #f2f2f2`,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
    </Flex>
  );
};

export default ControllsContainer;
