/**@jsx jsx */
import { jsx } from 'theme-ui';
import { FC } from 'react';

const Container: FC = (props: any) => (
  <div
    {...props}
    sx={{
      m: '0 auto',
      width: '95%',
      maxWidth: '1200px',
      minHeight: '100vh',
    }}
  />
);

export default Container;
