import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AddButton } from '../../components/buttons/CircleButton';
import { useCreateEventFormContext } from '../../components/create-event/CreateEventFormContext';
import {
  EventGridItem,
  EventsLayoutGrid,
  EventsLayoutSwitch,
  useGridLayoutSwitch,
} from '../../components/EventsGrid';
import { FilterSwitch } from '../../components/FilterSwitch';
import LoadingIndicator from '../../components/icons/Loading';
import { Container, PageLayout } from '../../components/layouts';
import ControllsContainer from '../../components/layouts/ControllsContainer';
import FullPageModal from '../../components/layouts/FullPageModal';
import FullPageLoad from '../../components/LoadingFullPage';
import { useUser } from '../../store/models/auth/selectors';
import {
  useEventsLoading,
  useShownEvents,
} from '../../store/models/events/selectors';
import { EventInState } from '../../types';
import CreateEventFormCard from '../CreateEvent';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const shown = useShownEvents();
  const loading = useEventsLoading();

  useEffect(() => {
    dispatch.events.loadEvents();
  }, []);

  const { layout, changeLayout } = useGridLayoutSwitch();
  const { open, setOpen } = useCreateEventFormContext();

  return (
    <PageLayout>
      <Container>
        <ControllsContainer>
          <FilterSwitch />
          <EventsLayoutSwitch current={layout} changeLayout={changeLayout} />
        </ControllsContainer>
        {loading ? (
          <FullPageLoad />
        ) : (
          <EventsLayoutGrid layout={layout}>
            {loading && <LoadingIndicator />}
            {shown.map((ev: EventInState) => (
              <EventGridItem
                key={ev.id}
                layout={layout}
                event={ev}
                // @ts-ignore
                joined={ev.attendees.includes(user.id)}
                isOwner={!!user && user.id === ev.owner.id}
              />
            ))}
          </EventsLayoutGrid>
        )}
      </Container>
      {!open && <AddButton onClick={() => setOpen(true)} />}
      <FullPageModal open={open}>
        <CreateEventFormCard />
      </FullPageModal>
    </PageLayout>
  );
};

export default Dashboard;
