import { useSelector } from 'react-redux';
import store, { RootState } from '../../';
import { EventInState, EventsFilter } from '../../../types';

export const useEventsFilter = (): EventsFilter =>
  useSelector((state: RootState) => state.events.filter);

export const useEventsError = (): string | null =>
  useSelector((state: RootState) => state.events.error);

export const useShownEvents = (): EventInState[] =>
  useSelector((state: RootState) => store.select.events.shown(state));

export const useUserEvents = () =>
  useSelector((state: RootState) => store.select.events.usersEvents(state));

export const useAttendingEvents = () =>
  useSelector((state: RootState) => store.select.events.attendingEvents(state));

export const useEventsLoading = (): boolean =>
  useSelector((state: RootState) => state.events.loading);

// todo: memoized
export const useShownEvent = (id: string) =>
  useSelector((state: RootState) => {
    if (!state.events.events[id]) {
      return null;
    }
    const event = state.events.events[id];
    const attendees = [
      ...event.attendees.map((attId: string) => {
        if (attId === state?.auth?.user?.id) {
          const { firstName, lastName, id } = state.auth.user;
          return { firstName, lastName, id };
        }
        const { firstName, lastName, id } = state.events.attendees[attId];
        return { firstName, lastName, id };
      }),
    ];

    return { event, attendees };
  });
