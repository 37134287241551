import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { Box } from 'theme-ui';
import { IconButt } from '../buttons';

const DropdownBoxTriangle = () => (
  <Box
    sx={{
      position: 'absolute',
      color: 'white',
      top: '-12px',
      right: 20,
    }}
  >
    <svg
      width='29'
      height='12'
      viewBox='0 0 29 12'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.4546 0C19.4546 0 23.4546 12 28.4546 12C33.4546 12 -4.54538 12 0.45462 12C5.45462 12 9.45462 0 14.4546 0Z'
        fill='currentColor'
      />
    </svg>
  </Box>
);

const DropdownContent = forwardRef(
  (
    { children, open = false }: { children: ReactNode; open: boolean },
    ref: React.Ref<HTMLDivElement>
  ) => (
    <Box
      ref={ref}
      style={{
        opacity: open ? 1 : 0,
        transform: open ? 'scale(1)' : 'scale(0)',
      }}
      sx={{
        transition: 'all 0.2s ease',
        position: 'absolute',
        top: 40,
        right: -24,
        zIndex: 9999,
      }}
    >
      <DropdownBoxTriangle />
      <Box
        sx={{
          p: [4],
          width: [160],

          borderRadius: 'dropdown',
          backgroundColor: 'white',
          boxShadow: 'dropdown',
        }}
      >
        {children}
      </Box>
    </Box>
  )
);

export const Dropdown: React.FC = ({ children }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => document.removeEventListener('click', handleClickOutside);
  }, [isDropdownOpen, ref]);

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButt
        active={isDropdownOpen}
        onClick={() => setDropdownOpen(!isDropdownOpen)}
      >
        <IoMdArrowDropdown size={20} />
      </IconButt>
      <DropdownContent
        open={isDropdownOpen}
        // @ts-ignore
        ref={ref}
      >
        {children}
      </DropdownContent>
    </Box>
  );
};

export default Dropdown;
