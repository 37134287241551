export default {
  breakpoints: ['350px', '600px', '900px', '1100px', '1400px'],
  colors: {
    text: '#323C46',
    muted: '#C9CED3',
    textGrey: '#565D5A',
    textMediumGrey: '#A9AEB4',
    textDarkGrey: '#949EA8',
    textLightGrey: '#D2D6DA',
    lightGrey: '#D9DCE1',
    lightGreyDarken: '#C4C9D1',
    yetAnotherGrey: '#7D7878',
    IbetThisAintTheLastGrey: '#CACDD0',
    green: '#22D486',
    greenDarken: '#20BD78',
    red: '#FF4081',
    redDarken: '#E73370',
    background: '#f2f2f2',
  },
  fonts: {
    body: `'Hind', sans-serif`,
    heading: `'Hind', sans-serif`,
  },
  lineHeights: {
    heading: '48px',
    text: '24px',
    button: '32px',
    buttonSmall: '16px',
  },
  radii: {
    button: 4,
    card: 4,
    dropdown: 14,
  },
  fontSizes: [12, 14, 16, 18, 22, 28, 36],
  fontWeights: {
    heading: 400,
    semiBold: 600,
  },
  space: [0, 2, 4, 8, 16, 24, 32, 40, 60, 64, 70, 120, 170],
  shadows: {
    card: '0 2px 3px rgba(0, 0, 0, 0.125)',
    dropdown: '0px 5px 15px rgba(0, 0, 0, 0.198087)',
    circleButton: '0px 6px 9px rgba(0, 0, 0, 0.15)',
  },
  text: {
    heading: {
      color: 'text',
      fontSize: [5],
      fontWeight: 400,
    },
    subheading: {
      color: 'textDarkGrey',
      fontFamily: 'body',
      fontSize: [3],
      lineHeight: 'text',
    },
    submitError: {
      color: 'red',
      fontFamily: 'body',
    },
    caps: {
      textTransform: 'uppercase',
    },
    cardHeading: {
      fontSize: [4],
      lineHeight: 1.5,
      // lineHeight: 'heading',
    },
    eventListCardHeading: {
      fontSize: [3],
      lineHeight: 1,
    },
    cardDescription: {
      fontSize: [2],
      lineHeight: 'text',
      fontFamily: 'body',
      color: 'textDarkGrey',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    cardDate: {
      fontSize: [1],
      lineHeight: 'text',
      color: 'IbetThisAintTheLastGrey',
      fontFamily: 'body',
    },
    eventAuthor: {
      fontSize: [1],
      fontFamily: 'body',
      color: 'yetAnotherGrey',
    },
    controllText: {
      color: 'textMediumGrey',
      fontWeight: '600',
      fontSize: [1],
      textTransform: 'uppercase',
      '& > strong': { color: 'text' },
    },
    error: {
      color: 'red',
      fontSize: [2],
    },
  },
  forms: {
    input: {
      border: 'none',
      outline: 'none',
      width: '100%',
      fontSize: [3],
      color: 'text',
    },
    label: {
      color: 'textLightGrey',
    },
  },
  cards: {
    form: {
      px: [4, null, 6],
      py: [5, null, 7],
      textAlign: 'center',
      borderRadius: 'card',
      boxShadow: 'card',
      width: ['100%'],
      maxWidth: [420],
      height: 'fit-content',
      background: '#ffff',
    },
    event: {
      background: '#ffff',
      p: [5, null, 6],
      boxShadow: 'card',
      minHeight: [300],
      width: '100%',
      borderRadius: 'card',
      display: 'flex',
      flexDirection: 'column',
    },
    eventList: {
      background: '#ffff',
      borderRadius: 'card',
      boxShadow: 'card',
      px: [4, null, 5],
      py: [4],
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: [
        '1.5fr 0.5fr',
        null,
        null,
        '1fr 1fr 0.5fr 0.75fr 0.2fr 0.5fr',
      ],
      gridTemplateRows: ['1fr 0.75fr 1fr 0.25fr 0.25fr', null, null, '1fr'],
      gridTemplateAreas: [
        `
      "title title"
      "desc desc"
      "desc desc"
      "date button"
      "capac button"
      `,
        null,
        null,
        `"title desc owner date capac button"`,
      ],
      gridGap: [0, null, null, 3],
    },
  },
  buttons: {
    big: {
      width: 240,
      height: 57,
      lineHeight: 'button',
    },
    med: {
      width: 200,
    },
    small: {
      width: 100,
      height: 32,
      lineHeight: 'buttonSmall',
      fontSize: [1],
    },
    primary: {
      letterSpacing: '1px',
      py: ['12px'],
      px: [5],
      fontSize: [2],
      bg: 'green',
      '&:hover': {
        bg: 'greenDarken',
      },
      lineHeight: 'button',
    },
    green: {
      bg: 'green',
      '&:hover': {
        bg: 'greenDarken',
      },
    },
    red: {
      bg: 'red',
      '&:hover': {
        bg: 'redDarken',
      },
    },
    grey: {
      bg: 'lightGrey',
      '&:hover': {
        bg: 'lightGreyDarken',
      },
    },
    black: {
      bg: 'text',
      '&:hover': {
        bg: 'textGrey',
      },
    },
    text: {
      p: 0,
      backgroundColor: 'transparent',
      background: 'transparent',
      color: 'textMediumGrey',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        color: 'text',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    delete: {
      color: 'red',
      decoration: 'underline',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      p: 0,
      '&:hover': { textDecoration: 'underline', color: 'red' },
      lineHeight: 'text',
      fontSize: [1],
    },
  },
  links: {
    black: {
      color: 'text',
      fontSize: [3],
      ml: [3],
    },
    signInLink: {
      color: 'muted',
      fontSize: [1],
      fontWeight: 400,
      transition: 'color 0.1s ease-in-out',
      '&:hover': {
        color: 'textDarkGrey',
        '& > strong': {
          color: 'textDarkGrey',
        },
      },
      '& > strong': {
        fontWeight: 500,
        color: 'textDarkGrey',
      },
    },
  },
  styles: {
    root: {
      backgroundColor: 'background',
      '*': {
        p: 0,
        m: 0,
        boxSizing: 'border-box',
        fontFamily: 'Hind, sans-serif',
      },

      a: {
        textDecoration: 'none',
        color: 'text',
        '&:hover, &:focus': {
          color: 'text',
          textDecoration: 'none',
        },
      },
    },
  },
};
