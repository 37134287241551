import { Theme } from 'theme-ui';

export const mapThemeUiVariants = ({
  variants,
  key,
  theme,
}: {
  variants: string[] | undefined;
  key: string;
  theme: Theme;
}) => {
  if (!variants) {
    return null;
  }

  let styles = {};

  //@ts-ignore
  for (const variant in theme[key]) {
    if (variants.includes(variant)) {
      // @ts-ignore
      styles = { ...styles, ...theme[key][variant] };
    }
  }

  return styles;
};
