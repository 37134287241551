import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthenticated } from '../../store/models/auth/selectors';
import { DASHBOARD } from '../../routes/const';
/**
 * Redirects from the page if user is already authenticated.
 * Uses react-router-dom history and location.
 * If location.state.from.pathname is set, it uses this value,
 * otherwise redirects to /.
 */
const RedirectAuthenticated = () => {
  const { state } = useLocation();
  const history = useHistory();
  const authenticated = useAuthenticated();

  useEffect(() => {
    if (authenticated) {
      history.push(
        state && state.from.pathname !== null ? state.from.pathname : DASHBOARD
      );
    }
  }, [authenticated, history, state]);

  return null;
};

export default RedirectAuthenticated;
