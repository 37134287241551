import React from 'react';

const BinIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0H16V16H0V0Z'
      stroke='black'
      strokeOpacity='0.01'
      strokeWidth='0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 12.6666C4 13.4 4.6 14 5.33333 14H10.6667C11.4 14 12 13.4 12 12.6666V4.66663H4V12.6666ZM12.6668 2.66667H10.3335L9.66683 2H6.3335L5.66683 2.66667H3.3335V4H12.6668V2.66667Z'
      fill='#FF4081'
    />
  </svg>
);

export default BinIcon;
