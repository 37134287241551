import { CreateEventBody } from '../../types/Event';
import { apiAuthRequest, apiRequest } from '../common';

export const getAll = async () => {
  return await apiRequest('/events');
};

export const getOne = async (id: string) => {
  return await apiRequest(`/events/${id}`);
};

export const joinEvent = async (id: string) => {
  return await apiAuthRequest(`/events/${id}/attendees/me`, {
    method: 'POST',
  });
};

export const leaveEvent = async (id: string) => {
  return await apiAuthRequest(`/events/${id}/attendees/me`, {
    method: 'DELETE',
  });
};

export const createEvent = async (body: CreateEventBody) => {
  return await apiAuthRequest(`/events`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const updateEvent = async (id: string, body: CreateEventBody) => {
  return await apiAuthRequest(`/events/${id}`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const deleteEvent = async (id: string) => {
  return await apiAuthRequest(`/events/${id}`, {
    method: 'DELETE',
  });
};
