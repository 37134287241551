import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Text } from 'theme-ui';
import AttendeesCard from '../../components/AttendeesCard';
import { AddButton } from '../../components/buttons/CircleButton';
import { useCreateEventFormContext } from '../../components/create-event/CreateEventFormContext';
import { EventGridCard } from '../../components/EventsGrid';
import {
  Container,
  EventDetailContent,
  PageLayout,
} from '../../components/layouts';
import ControllsContainer from '../../components/layouts/ControllsContainer';
import FullPageModal from '../../components/layouts/FullPageModal';
import FullPageLoad from '../../components/LoadingFullPage';
import { useUser } from '../../store/models/auth/selectors';
import {
  useEventsLoading,
  useShownEvent,
} from '../../store/models/events/selectors';
import { EventPageParams } from '../../types';
import CreateEventFormCard from '../CreateEvent';

const EventDetail = () => {
  const { id } = useParams<EventPageParams>();

  const dispatch = useDispatch();
  const shownEvent = useShownEvent(id);
  const loading = useEventsLoading();
  const user = useUser();
  const { open, setOpen } = useCreateEventFormContext();

  useEffect(() => {
    if (!shownEvent) {
      dispatch.events.loadOne(id);
    }
  }, [shownEvent, id]);

  return (
    <PageLayout>
      {!shownEvent || loading ? (
        <FullPageLoad />
      ) : (
        <Container>
          <ControllsContainer>
            <Text variant='controllText'>detail event #{id}</Text>
            {}
          </ControllsContainer>
          <EventDetailContent>
            <Box>
              <EventGridCard
                key={shownEvent.event.id}
                // @ts-ignore
                event={shownEvent.event}
                // @ts-ignore
                joined={shownEvent.event.attendees.includes(user.id)}
                isOwner={!!user && user.id === shownEvent.event.owner.id}
              />
            </Box>

            <AttendeesCard user={user!} attendees={shownEvent.attendees} />
          </EventDetailContent>
        </Container>
      )}
      {!open && <AddButton onClick={() => setOpen(true)} />}
      <FullPageModal open={open}>
        <CreateEventFormCard />
      </FullPageModal>
    </PageLayout>
  );
};

export default EventDetail;
