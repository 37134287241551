import { isPast } from 'date-fns';
import { FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Text } from 'theme-ui';
import AttendeesCard from '../../components/AttendeesCard';
import { Button } from '../../components/buttons';
import { UpdateButton } from '../../components/buttons/CircleButton';
import Card from '../../components/Card';
import EventForm from '../../components/forms/EventForm';
import BinIcon from '../../components/icons/DeleteIcon';
import {
  Container,
  EventDetailContent,
  PageLayout,
} from '../../components/layouts';
import ControllsContainer from '../../components/layouts/ControllsContainer';
import FullPageLoad from '../../components/LoadingFullPage';
import { DASHBOARD } from '../../routes/const';
import { useUser } from '../../store/models/auth/selectors';
import {
  useEventsLoading,
  useShownEvent,
} from '../../store/models/events/selectors';
import { CreateEventPayload, EventPageParams } from '../../types';
import { composeDate } from '../../utils/dates';

const EventEdit = () => {
  const { id } = useParams<EventPageParams>();

  const dispatch = useDispatch();
  const shownEvent = useShownEvent(id);
  const loading = useEventsLoading();
  const user = useUser();

  const history = useHistory();

  const onSubmit = (
    values: CreateEventPayload,
    actions: FormikHelpers<CreateEventPayload>
  ) => {
    const { date, time, ...rest } = values;
    const startsAt = composeDate(new Date(date), time);
    if (isPast(startsAt)) {
      actions.setErrors({
        time: 'This time is in the past, mate',
      });
      actions.setSubmitting(false);
      return;
    }

    dispatch.events.updateEvent({
      id,
      body: {
        ...rest,
        startsAt: startsAt,
      },
    });
    actions.setSubmitting(false);
    history.push(DASHBOARD);
  };

  useEffect(() => {
    if (!shownEvent) {
      dispatch.events.loadOne(id);
    }
  }, [shownEvent, id]);

  let values;
  if (shownEvent && !loading) {
    const { title, description, startsAt, capacity } = shownEvent?.event!;
    const [_, time] = startsAt.split('T');
    values = {
      title,
      description,
      capacity,
      date: new Date(startsAt),
      time: time.substring(0, 5),
    };
  }

  const handleDelete = () => {
    dispatch.events.deleteEvent(id);
    history.push(DASHBOARD);
  };

  return (
    <PageLayout>
      {!shownEvent || loading ? (
        <FullPageLoad />
      ) : (
        <Container>
          <ControllsContainer>
            <Text variant='controllText'>detail event #{id}</Text>
            <Button
              variants={['text', 'delete']}
              onClick={() => handleDelete()}
            >
              <BinIcon />
              <span style={{ marginLeft: 4 }}>delete event</span>
            </Button>
          </ControllsContainer>
          <EventDetailContent>
            <Box>
              <Card>
                {shownEvent && (
                  <EventForm
                    id='update-event-form'
                    onSubmit={onSubmit}
                    initialValues={values}
                  />
                )}
              </Card>
            </Box>
            <AttendeesCard user={user!} attendees={shownEvent.attendees} />
          </EventDetailContent>
          <UpdateButton type='submit' form='update-event-form' />
        </Container>
      )}
    </PageLayout>
  );
};

export default EventEdit;
