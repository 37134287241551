import React from 'react';
import LoadingIndicator from '../icons/Loading';
import { Centered } from '../layouts';

const FullPageLoad = () => {
  return (
    <Centered>
      <LoadingIndicator />
    </Centered>
  );
};

export default FullPageLoad;
