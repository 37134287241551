/**@jsx jsx */
import { jsx } from 'theme-ui';
import { FC } from 'react';

const Centered: FC = (props) => (
  <div
    {...props}
    sx={{
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  />
);
export default Centered;
