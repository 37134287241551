/**@jsx jsx */
import { jsx } from 'theme-ui';
import { forwardRef, InputHTMLAttributes } from 'react';
import { HiEye } from 'react-icons/hi';
import { Dispatch, SetStateAction } from 'react';

const InputWrap = ({ children }: { children: React.ReactNode[] }) => (
  <div
    sx={{
      position: 'relative',
      height: 56,
      width: '100%',
      marginBottom: [5],
    }}
  >
    {children}
  </div>
);

const Label: React.FC<{
  hasError: boolean;
  hasValue: boolean;
  htmlFor: string;
  focused?: boolean;
}> = ({
  hasError = false,
  hasValue = false,
  htmlFor,
  children,
  focused = false,
  ...props
}) => (
  <label
    {...props}
    sx={{
      fontFamily: 'body',
      position: 'absolute',
      color: 'muted',
      width: '100%',
      bottom: 0,
      left: 0,
      lineHeight: 'text',
      pointerEvents: 'none',
      borderBottom: '1px solid',
      borderColor: focused ? 'text' : hasError ? 'red' : 'muted',
    }}
  >
    <span
      style={{
        transform:
          hasValue || focused
            ? 'translate3d(0, -24px, 0)'
            : 'translate3d(0, 0, 0)',
      }}
      sx={{
        position: 'absolute',
        lineHeight: 'text',
        bottom: 2,
        left: 0,
        transition: 'transform 0.1s linear',
        fontSize: hasValue || focused ? [1] : [3],
      }}
    >
      {children}
    </span>
  </label>
);

const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => (
  <input
    ref={ref}
    {...props}
    sx={{
      fontFamily: 'body',
      position: 'absolute',
      bottom: 2,
      left: 0,
      width: '100%',
      backgroundColor: 'transparent',
      fontSize: [3],
      lineHeight: '24px',
      border: 'none',
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    }}
  />
));

const InputError = ({ message }: { message: string | null }) => (
  <div
    sx={{
      position: 'absolute',
      fontSize: [3],
      left: 0,
      fontFamily: 'body',
      bottom: [-6],
      lineHeight: '24px',
      color: 'red',
      opacity: !!message ? 1 : 0,
      transition: 'opacity 0.1s linear',
    }}
  >
    {message}
  </div>
);

const ShowPassword = ({
  shown,
  showPassword,
}: {
  shown: boolean;
  showPassword: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <span
      onClick={() => showPassword(!shown)}
      sx={{
        lineHeight: 'text',
        position: 'absolute',
        bottom: 0,
        right: 2,
        cursor: 'pointer',
        transition: 'color 0.1s linear',
        color: shown ? 'lightGreyDarken' : 'lightGrey',
        '&:hover': {
          color: 'lightGreyDarken',
        },
      }}
    >
      <HiEye size={24} />
    </span>
  );
};

export { Input, Label, InputWrap, InputError, ShowPassword };
