import React from 'react';
import { Box, ButtonProps } from 'theme-ui';
import { Button } from '.';
import { BiPlus } from 'react-icons/bi';

const TickIcon = () => (
  <svg
    width='18'
    height='14'
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.99991 11.1701L1.82991 7.00009L0.409912 8.41009L5.99991 14.0001L17.9999 2.00009L16.5899 0.590088L5.99991 11.1701Z'
      fill='white'
    />
  </svg>
);

export const AddButton: React.FC<ButtonProps> = (props) => (
  <Box
    sx={{
      position: 'fixed',
      bottom: [6],
      right: [6],
    }}
  >
    <CircleButton {...props} variant='black'>
      <BiPlus size={20} />
    </CircleButton>
  </Box>
);

export const UpdateButton: React.FC<ButtonProps> = (props) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: [6],
        right: [6],
      }}
    >
      <CircleButton {...props} variant='green'>
        <TickIcon />
      </CircleButton>
    </Box>
  );
};

const CircleButton: React.FC<ButtonProps> = ({
  onClick,
  variant = 'green',
  color,
  children,
  ...props
}) => (
  <Button
    onClick={onClick}
    variant={variant}
    sx={{
      cursor: 'pointer',
      height: 56,
      width: 56,
      p: 0,
      borderRadius: '50%',
      color,
      boxShadow: 'circleButton',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    {...props}
  >
    {children}
  </Button>
);
