import { Models } from '@rematch/core';
import auth from './auth';
import events from './events';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  events: typeof events;
}

export const models: RootModel = { auth, events };
