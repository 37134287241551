import * as Yup from 'yup';

export const CreateEventSchema = Yup.object().shape({
  title: Yup.string().min(1).required('Cool, untitled...'),
  description: Yup.string().min(1).required('Description has to be filled up'),
  time: Yup.string().required('What time is your event?'),
  date: Yup.date()
    .min(new Date(), 'So... you travel back in time?')
    .required('When is your event?'),
  capacity: Yup.number().min(1).required('Coming alone ey?'),
});

export const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Not an email!').required('Email is required!'),
  password: Yup.string().required('Password is required!'),
});

export const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required('What should we call you?.'),
  lastName: Yup.string().required('Your last name, please'),
  email: Yup.string().email('Not an email!').required('Email is required!'),
  password: Yup.string().min(2).required('Password is required!'),
  confirmPassword: Yup.string().test(
    'passwords-match',
    'Its not a match! ⭐️',
    function (value) {
      return this.parent.password === value;
    }
  ),
});
