import { isPast } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../buttons';
import { Box, Text } from 'theme-ui';

const EventCardError: React.FC = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      bottom: [6],
      right: [0],
    }}
  >
    <Text variant='error'>{children}</Text>
  </Box>
);

const EventButton: React.FC<{
  id: string;
  isOwner: boolean;
  joined: boolean;
  variant?: string;
  startsAt: string;
}> = ({ id, isOwner, joined, startsAt }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, showErrorMessage] = useState(false);

  const showError = async () => {
    showErrorMessage(true);
    setTimeout(() => {
      showErrorMessage(false);
    }, 1000);
  };

  const joinEvent = async () => {
    if (isPast(new Date(startsAt))) {
      showError();
      return;
    }
    dispatch.events.joinEvent(id);
  };

  const leaveEvent = async () => {
    dispatch.events.leaveEvent(id);
  };

  if (isOwner) {
    return (
      <Button
        variants={['grey', 'card', 'small']}
        onClick={() => history.push(`/events/${id}/edit`)}
      >
        Edit
      </Button>
    );
  }

  if (joined) {
    return (
      <Button variants={['red', 'card', 'small']} onClick={leaveEvent}>
        Leave
      </Button>
    );
  }
  return (
    <Box sx={{ position: 'relative' }}>
      {errorMessage && (
        <EventCardError>You can't join a past event!</EventCardError>
      )}
      <Button onClick={joinEvent} variants={['green', 'small']}>
        Join
      </Button>
    </Box>
  );
};

export default EventButton;
