import { useState } from 'react';
import { LayoutType } from './EventsLayoutType';

const useGridLayoutSwitch = (initial: LayoutType = 'grid') => {
  const [layout, changeLayout] = useState<LayoutType>(initial);

  return { layout, changeLayout };
};

export default useGridLayoutSwitch;
