import React from 'react';
import { useEventsFilter } from '../../store/models/events/selectors';
import { useDispatch } from 'react-redux';
import { Button, Flex, Text } from 'theme-ui';
import { EventsFilter } from '../../types';
import Dropdown from '../Dropdown';
import { useBreakpoint } from 'react-use-size/dist/useBreakpoint';

const FilterButton = ({ active = false, ...props }) => (
  <Button
    variant='text'
    {...props}
    sx={{
      p: [0],
      fontSize: [0],
      fontWeight: 600,
      cursor: 'pointer',
      color: active ? 'text' : 'textMediumGrey',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: 'text',
      '&:hover': {
        color: 'text',
      },
    }}
  />
);

const FilterSwitch = () => {
  const filter = useEventsFilter();
  const dispatch = useDispatch();

  const isMobile = useBreakpoint(600);

  const changeFilter = (filter: EventsFilter) => {
    dispatch.events.setFilter(filter);
  };

  return (
    <Flex sx={{ width: ['auto', null, 300], justifyContent: 'space-between' }}>
      {isMobile ? (
        <>
          <Text variant='controllText'>
            SHOW: <strong>{filter} EVENTS</strong>
          </Text>
          <Dropdown>
            <FilterButton
              active={filter === 'all'}
              onClick={() => changeFilter('all')}
            >
              All events
            </FilterButton>

            <FilterButton
              active={filter === 'future'}
              onClick={() => changeFilter('future')}
            >
              Future events
            </FilterButton>

            <FilterButton
              active={filter === 'past'}
              onClick={() => changeFilter('past')}
            >
              Past events
            </FilterButton>
          </Dropdown>
        </>
      ) : (
        <>
          <FilterButton
            active={filter === 'all'}
            onClick={() => changeFilter('all')}
          >
            All events
          </FilterButton>

          <FilterButton
            active={filter === 'future'}
            onClick={() => changeFilter('future')}
          >
            Future events
          </FilterButton>

          <FilterButton
            active={filter === 'past'}
            onClick={() => changeFilter('past')}
          >
            Past events
          </FilterButton>
        </>
      )}
    </Flex>
  );
};

export { FilterSwitch };
