import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { Dispatch, RootState } from '../..';
import * as API from '../../../api';
import {
  CreateEventBody,
  EventsFilter,
  EventsInState,
  EventInState,
  EventResp,
  EventsState,
} from '../../../types/Event';
import { User } from '../../../types/User';
import { dateFilter } from '../../../utils/dates';
import { normalize, schema } from 'normalizr';

const attendee = new schema.Entity('attendees');

const attendeeListSchema = new schema.Array(attendee);

const event = new schema.Entity('events', {
  attendees: attendeeListSchema,
});
const eventsListSchema = new schema.Array(event);

const normalizeEvents = (events: EventResp[]) =>
  normalize(events, eventsListSchema).entities;

const eventsInitState = {
  events: {},
  attendees: {},
  loading: true,
  error: null,
  filter: 'all',
};

const events = createModel<RootModel>()({
  state: eventsInitState as EventsState,
  reducers: {
    setFilter(state, filter: EventsFilter) {
      state.filter = filter;
      return state;
    },
    removeEvent(state, id) {
      delete state.events[id];
      return state;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
      return state;
    },
    addEvents(state, events: EventResp[]) {
      const normalized = normalizeEvents(events);
      state.events = { ...state.events, ...normalized.events };
      state.attendees = {
        ...state.attendees,
        ...normalized.attendees,
      };
      state.loading = false;
      return state;
    },
    addEventAttendee(state, { id, uid }: { id: string; uid: string }) {
      state.events[id].attendees.push(uid);

      return state;
    },
    removeEventAttendee(state, { id, uid }) {
      state.events[id].attendees = state.events[id].attendees.filter(
        (a: string) => a !== uid
      );
      delete state.attendees[uid];
      return state;
    },
    setError(state, error) {
      state.error = error;
      state.loading = false;
      return state;
    },
    setUpdatedEvent(state, { id, body }) {
      state.events[id] = body;
      state.loading = false;
      return state;
    },
  },
  effects: (dispatch: Dispatch) => ({
    async loadEvents() {
      try {
        dispatch.events.setLoading(true);
        const response = await API.getAll();
        if (response.error) {
          dispatch.events.setError(response.error);
        }
        dispatch.events.addEvents(response);
      } catch (error) {
        dispatch.events.setError(error.messsage | error);
      }
    },
    async joinEvent(id: string, rootState: RootState) {
      try {
        const uid = rootState.auth?.user?.id;
        dispatch.events.addEventAttendee({ id, uid });
        const res = await API.joinEvent(id);
        if (res.error) {
          dispatch.events.setError(res.error.message | res.error);
          dispatch.events.removeEventAttendee({ id, uid });
        }
      } catch (error) {}
    },
    async leaveEvent(id: string, rootState: RootState) {
      try {
        const uid = rootState.auth?.user?.id;
        dispatch.events.removeEventAttendee({ id, uid });
        const res = await API.leaveEvent(id);
        if (res.error) {
          dispatch.events.addEventAttendee({ id, uid });
          dispatch.events.setError(res.error.message | res.error);
        }
      } catch (error) {}
    },
    async createEvent(body: CreateEventBody) {
      try {
        dispatch.events.setLoading(true);
        const res = await API.createEvent(body);
        if (res.error) {
          dispatch.events.setError(res.error.message || res.error);
        } else {
          dispatch.events.addEvents([res]);
        }
      } catch (error) {
        dispatch.events.setError(error.message || error);
      }
    },
    async loadOne(id) {
      dispatch.events.setLoading(true);
      try {
        const res = await API.getOne(id);
        if (!res.error) {
          dispatch.events.addEvents([res]);
        } else {
          dispatch.events.setError(res.error.message | res.error);
        }
      } catch (error) {}
    },
    async updateEvent({ id, body }: { id: string; body: CreateEventBody }) {
      try {
        dispatch.events.setUpdatedEvent({ id, body });
        // todo: call api
      } catch (error) {}
    },
    async deleteEvent(id) {
      try {
        dispatch.events.removeEvent(id);
        // todo: call api
      } catch (error) {}
    },
  }),
  // @ts-ignore (rematch struggles with selector types 😢)
  selectors: (slice, createSelector) => ({
    shown() {
      return createSelector(
        (rootState: RootState) => rootState.events.events,
        (rootState: RootState) => rootState.events.filter,
        (all: EventsInState, filter: EventsFilter) => {
          const arr: EventInState[] = [];
          Object.values(all).forEach((event: EventInState) => {
            if (dateFilter(event.startsAt, filter)) {
              arr.push(all[event.id]);
            }
          });
          const sorted = arr.sort(
            (a, b) => Date.parse(a.startsAt) - Date.parse(b.startsAt)
          );
          return sorted;
        }
      );
    },
    usersEvents() {
      return createSelector(
        (rootState: RootState) => rootState.events.events,
        (rootState: RootState) => rootState.auth.user,
        (all: {}, user: User) => {
          const arr: EventInState[] = [];

          //@ts-ignore
          Object.values(all).forEach((event: EventInState) => {
            console.log(event);

            if (
              event.owner.id === user.id ||
              //@ts-ignore
              event.attendees.includes(user.id)
            ) {
              arr.push(event);
            }
          });
          return arr;
        }
      );
    },
  }),
});

export default events;
