import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { Blockquote } from './Blockquote';

const QuoteContent = () => (
  <Flex
    sx={{
      marginBottom: 86,
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 310,
      height: 160,
    }}
  >
    <Blockquote>
      <Text
        as='p'
        sx={{ fontFamily: 'Playfair Display', fontSize: [6], color: 'white' }}
      >
        Great kid! Don't get cocky!
      </Text>
    </Blockquote>
    <Box sx={{ content: '""', width: '12px', bg: 'green', height: '3px' }} />
    <Text as='p' sx={{ color: 'textDarkGrey', fontSize: [3] }}>
      Han Solo
    </Text>
  </Flex>
);

const QuoteLayout = () => (
  <Box
    sx={{
      position: 'relative',
      zIndex: 200,
      flex: '1 0 auto',
      display: ['none', null, null, 'flex'],
      backgroundColor: 'text',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundImage: `url(/images/troopers@2x.png)`,
      height: '100vh',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <QuoteContent />
  </Box>
);

export default QuoteLayout;
