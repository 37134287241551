import { getAccessToken, getRefreshToken } from '../../utils/token';
import { refreshAccessToken } from '../auth';
import store from '../../store';

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

export const makeRequest = (url: string, options = {}) =>
  fetch(`${API_URL}${url}`, {
    headers: { 'Content-type': 'application/json', APIKey: API_KEY! },
    ...options,
  });

export const apiRequest = async (
  url: string,
  options?: RequestInit | undefined
) => {
  try {
    const res = await makeRequest(url, {
      ...options,
    });

    return await res.json();
  } catch (error) {
    throw new Error(`Network Error: ${error}`);
  }
};

/**
 * Make authorized request.
 * Tries to repeat the request if it fails with 401 for the first time.
 *
 * @param param0
 */
export const apiAuthRequest = async (
  url: string,
  options: RequestInit | undefined
) => {
  try {
    const accessToken = getAccessToken();
    let res = await makeRequest(url, {
      headers: {
        Authorization: `${accessToken}`,
        'Content-type': 'application/json',
        APIKey: API_KEY!,
      },
      ...options,
    });

    // invalid access token => try fetching a new token and retry request
    if (res.status === 401) {
      const refreshToken = getRefreshToken();

      if (refreshToken) {
        const refreshResp = await refreshAccessToken();
        res = await makeRequest(url, {
          headers: { Authorization: `Bearer ${refreshResp.accessToken}` },
          ...options,
        });
      }
    }
    // other errors => return response, should be handled accordingly to use validation errors.
    return await res.json();
  } catch (error) {
    // when unable to refresh token | 500 errors
    //@ts-ignore
    store.dispatch.auth.signOut();
    new Error(`Network Error: ${error}`);
  }
};
