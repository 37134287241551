import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import selectPlugin from '@rematch/select';
import { models, RootModel } from './models';
import logger from 'redux-logger';
import immerPlugin from '@rematch/immer';

const store = init<RootModel>({
  models,
  plugins: [
    immerPlugin(),
    //@ts-ignore
    selectPlugin(),
  ],
  redux: {
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production',
    },
    middlewares: [logger],
  },
});

export default store;

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
