import React from 'react';
import { BoxProps, Card as TUICard } from 'theme-ui';

const Card = ({ sx, ...props }: BoxProps) => (
  //@ts-ignore
  <TUICard
    sx={{
      bg: '#fff',
      boxShadow: 'card',
      borderRadius: 'card',
      p: [5, null, 6],
      minHeight: '100%',
      width: '100%',
      ...sx,
    }}
    {...props}
  />
);
export default Card;
