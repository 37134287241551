/**@jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';

const TextLink: React.FC<{ to: string; variant: string }> = ({
  to,
  children,
  variant,
}) => (
  <Link
    sx={{
      variant,
    }}
    to={to}
  >
    {children}
  </Link>
);

export default TextLink;
