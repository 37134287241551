import { SignInPayload, SignUpPayload, User } from '../../types/User';
import { getRefreshToken, setAccessToken } from '../../utils/token';
import { makeRequest } from '../common';
import { USER_EXISTS, USER_INVALID_PASSWORD_ERROR } from '../common/errors';

export const signIn = async (body: SignInPayload) => {
  try {
    const res = await makeRequest('/auth/native', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const data = await res.json();

    if (data.error && data.error === USER_INVALID_PASSWORD_ERROR) {
      throw new Error(
        'Oops! That email and password combination is not valid.'
      );
    }

    if (!res.ok) {
      throw new Error('Error occured!');
    }

    const accessToken = res.headers.get('Authorization')!;
    const refreshToken = res.headers.get('Refresh-Token')!;
    return {
      user: data,
      accessToken,
      refreshToken,
    };
  } catch (error) {
    throw error;
  }
};

export const createUser = async (body: SignUpPayload) => {
  const res = await makeRequest('/users', {
    method: 'POST',
    body: JSON.stringify(body),
  });

  const data = await res.json();

  // map errors
  let error;
  if (!res.ok && data.error) {
    switch (data.error) {
      case USER_EXISTS:
        error = 'User already exists!';
        break;
      default:
        error = 'Error happened!';
    }
    return {
      error,
    };
  }

  return data;
};

/**
 * Returns User and accessToken if succeedes.
 *
 * @throws new Error if !res.ok.
 * @returns promise resolving to object with user and new access token.
 *
 */
export const refreshAccessToken = async (): Promise<RefreshTokenResponse> => {
  try {
    const refreshToken = getRefreshToken();

    const res = await makeRequest('/auth/native', {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        `Failed to refresh the access token!, ${res.status}${
          data.error.message || data.error
        })`
      );
    }

    const accessToken = res.headers.get('Authorization')!;
    setAccessToken(accessToken);
    return { user: data as User, accessToken };
  } catch (error) {
    throw error;
  }
};

interface RefreshTokenResponse {
  user: User;
  accessToken: string;
}

interface ErrorResponse {
  error: string;
  statusCode: number;
}
