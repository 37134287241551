import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { Dispatch } from '../..';
import {
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../../../utils/token';
import * as API from '../../../api';
import { SignUpPayload, User } from '../../../types/User';

export const initState = {
  loading: true,
  error: null,
  user: null,
};

export type AuthState = {
  loading: boolean;
  error: string | null;
  user: User | null;
};

const auth = createModel<RootModel>()({
  state: initState as AuthState,
  reducers: {
    signOutSuccess(state) {
      state = { ...initState, loading: false };
      return state;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
      return state;
    },
    setError(state, error: string) {
      state.error = error;
      state.loading = false;
      return state;
    },
    setUser(state, user: User) {
      state.user = user;
      state.loading = false;
      return state;
    },
  },
  effects: (dispatch: Dispatch) => ({
    async signIn(payload: { email: string; password: string }) {
      try {
        dispatch.auth.setLoading(true);
        const { user, refreshToken, accessToken } = await API.signIn(payload);
        setRefreshToken(refreshToken);
        setAccessToken(accessToken);
        dispatch.auth.setUser(user);
      } catch (error) {
        dispatch.auth.setError(error.message || error);
      }
    },
    async refreshAuth() {
      try {
        dispatch.auth.setLoading(true);
        const res = await API.refreshAccessToken();
        if (res) {
          setAccessToken(res.accessToken);
          dispatch.auth.setUser(res.user);
        } else {
          dispatch.auth.signOut();
        }
      } catch (error) {
        removeRefreshToken();
        dispatch.auth.signOutSuccess();
      }
    },
    async initAuth() {
      // this action is dispatched at the app load
      // checks whether there is a previously stored refresh
      // if so, it dispatches refreshAccessToken action, which signs the user in
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        await dispatch.auth.refreshAuth();
      } else {
        dispatch.auth.setLoading(false);
      }
    },
    async signUp(payload: SignUpPayload) {
      try {
        dispatch.auth.setLoading(true);
        const res = await API.createUser(payload);
        if (!res.error) {
          // signs in the newly created user automatically
          dispatch.auth.signIn({
            password: payload.password,
            email: payload.email,
          });
        } else {
          dispatch.auth.setError(res.error.message || res.error);
        }
      } catch (error) {
        // 500 only, should show Error page
        console.log('Error');
        // throw error;
      }
    },
    signOut() {
      removeRefreshToken();
      removeAccessToken();
      dispatch.auth.signOutSuccess();
    },
  }),
});

export default auth;
